<template>
  <BaseLayout>

    <section class="ol-dashboard ol-product-detail">
      <div class="ol-wrapper ol-wrapper--large">
        <div class="ol-dashboard--wrapper">
          <aside class="ol-dashboard__sidebar">
            <h4 class="ol-dashboard__sidebar-title">Profil Pengguna</h4>

            <nav class="ol-dashboard__sidebar-nav">
              <router-link to="/profile" class="ol-dashboard__sidebar-nav-link profile-menu">
                <i class="ol-fa fas fa-user"></i> Informasi Akun
              </router-link>
              <router-link to="/profile/wishlist" class="ol-dashboard__sidebar-nav-link profile-menu">
                  <i class="ol-fa fas fa-heart"></i> Wishlist
              </router-link>
              <router-link to="/profile/orders" class="ol-dashboard__sidebar-nav-link profile-menu">
                <i class="ol-fa fas fa-shopping-bag"></i> Pesanan
              </router-link>
              <router-link to="/profile/event" class="ol-dashboard__sidebar-nav-link profile-menu">
                <i class="ol-fa fas fa-gift"></i> Event
              </router-link>
              <router-link to="/profile/address" class="ol-dashboard__sidebar-nav-link profile-menu" >
                <i class="ol-fa fas fa-map-marker-alt"></i> Alamat
              </router-link>
              <router-link to="/profile/dropshipper" class="ol-dashboard__sidebar-nav-link profile-menu" >
                <i class="ol-fa fas fa-user"></i> Dropshipper
              </router-link>
              <router-link to="/profile/banks" class="ol-dashboard__sidebar-nav-link profile-menu ">
                <i class="ol-fa fas fa-credit-card"></i> Daftar Rekening
              </router-link>
              <router-link to="/profile/notifications" class="ol-dashboard__sidebar-nav-link profile-menu">
                <i class="ol-fa fas fa-bell"></i> Notifikasi
              </router-link>
              <!-- <router-link to="/profile/chats" class="ol-dashboard__sidebar-nav-link profile-menu">
                <i class="ol-fa fas fa-comment-alt"></i> Chat
              </router-link>
              <router-link to="/profile/vouchers" class="ol-dashboard__sidebar-nav-link profile-menu">
                <i class="ol-fa fas fa-ticket-alt"></i> Voucher
              </router-link> -->
              <router-link to="/profile/password" class="ol-dashboard__sidebar-nav-link profile-menu">
                <i class="ol-fa fas fa-lock"></i> Ubah Kata Sandi
              </router-link>
            </nav>
          </aside>
          <div class="ol-dashboard__container">
            <router-view></router-view>

            
          </div>
          <div v-if="recommended.length > 0" class="ol-product-content ol-product-related add-fix">
              <h2 class="ol-section-title">
                  <span>PRODUK <strong>REKOMENDASI</strong></span>
                      <i></i>
              </h2>
              <ProductLoader wrapclass="ol-product-content__center-list" :page="2" :qty="5" v-if="loadingRecommended"/>
              <ProductList :products="recommended" v-if="!loadingRecommended" /> 
          </div>
        </div>
      </div>
    </section>
  </BaseLayout>
</template>

<script>
import BaseLayout from "@/components/landing/BaseLayout.vue";
import {mapActions,mapGetters} from "vuex";
import ProductList from "@/components/product/ProductList.vue"
import ProductLoader from '@/components/loading/product/Content.vue'
export default {
  name: "Menu",
  components:{
    BaseLayout,
    ProductList,
    ProductLoader
  },
  data(){
    return {
			loadingRecommended:true,
    }
  },
  computed:{
		...mapGetters("product/detail", ["recommended"]),

  },
  methods:{
    activeMenu(){
      $(".profile-menu").removeClass("active");
      if((this.$route.name).search("Order") >= 0 ){
          $(".profile-menu[href='/profile/orders']").addClass("active")
      }else{
        $(".profile-menu[href='"+this.$route.path+"']").addClass("active")
      }
    }
  },
  updated(){
    this.activeMenu();
  },
  mounted(){
    this.activeMenu();
  }
};
</script>

<style></style>
